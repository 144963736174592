<template>
  <div class="project-info">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Info </CBreadcrumbItem>
    </teleport>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Index',
  inject: ['mountedComponent'],
}
</script>

<style lang="scss" scoped>
.project-info {
}
</style>
